// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import useSettings from '../hooks/useSettings';

// ----------------------------------------------------------------------

interface Props {
  loadingScreen?: boolean;
  title?: boolean;
  sx?: object;
}

export default function Logo({ loadingScreen = false, title = false, sx }: Props) {
  const theme = useTheme();
  const { themeMode } = useSettings();

  if (loadingScreen) {
    return (
      <Box sx={{ width: 45, height: 45 }} >
        <img alt={'Logo'} src="/../../logo_small.png" />
      </Box>
    );
  }

  var color = themeMode === 'light' ? theme.palette.grey[700] : '#FFF';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <img
        alt={'Logo'}
        onClick={() => {
          window.location.href = '/'
        }}
        src={title ? '/../../logo.png' : '/../../logo_small.png'}
        style={{
          maxHeight: '100px',
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          cursor: 'pointer',
        }}
      />

      {/* {title && (
        <Typography
          sx={{
            ml: 1,
            marginTop: '5px',
            color: color,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            flexDirection: 'column',
            fontFamily: 'Rubik, Inter, sans-serif',
            fontSize: { xs: 16, md: 20 },
            letterSpacing: '0.12em',
          }}
        >
          Aleon Digital 
        </Typography>
      )} */}
    </Box>
  );
}
