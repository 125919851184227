export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type AuthState = {
  playerId: string;
  jwt: string;
  refreshToken: string;
  sessionKey: string;
  client: any;
  winnings: string;
  entry: string;
  activeGames: any[];
  cert: string;
  showLoginForm: boolean;
  reqCounter: number;
  isTauriApp: boolean;
  isMuted: boolean;
};

export type LoginRequest = {
  id: string;
  cardSerialNumber?: string;
  email?: string;
  password?: string;
};

export type AuthContextType = {
  playerId: string;
  jwt: string;
  refreshToken: string;
  sessionKey: string;
  client: any;
  winnings: string;
  entry: string;
  activeGames: any[];
  cert: string;
  showLoginForm: boolean;
  reqCounter: number;
  isTauriApp: boolean;
  isMuted: boolean;
  login: (loginRequest: LoginRequest) => void;
  getBalance: () => void;
  doInit: () => Promise<InitResponse | undefined>;
  doSpin: (
    betAmount: number,
    lines: number,
    reelOffset: number[]
  ) => Promise<SpinResponse | undefined>;
  logout: VoidFunction;
  setJwt: (jwt: string) => Promise<void>;
  setPlayerId: (playerId: string) => Promise<void>;
  setRefreshToken: (refreshToken: string) => Promise<void>;
  setSessionKey: (sessionKey: string) => Promise<void>;
  setClient: (client: any) => Promise<void>;
  setWinnings: (winnings: string) => void;
  setEntry: (entry: string) => void;
  setCert: (cert: string) => Promise<void>;
  setShowLoginForm: (showLoginForm: boolean) => void;
  setReqCounter: (reqCounter: number) => void;
  setIsTauriApp: (isTauriApp: boolean) => void;
  setIsMuted: (isMuted: boolean) => void;

  initializeStation: (secret: string) => Promise<void>;
  refreshCertificate: () => Promise<void>;
};

export type InitResponse_Paytable = {
  paytable: number[];
  symbol: number;
  type: number;
};

export type InitResponse = {
  entry: string;
  lines: number;
  paytable: InitResponse_Paytable;
  reelOffset: number[];
  reelOffset0: string[];
  reelOffset1: string[];
  reelOffset2: string[];
  reelOffset3: string[];
  reelOffset4: string[];
  requestCounter: number;
  totalBetMax: number;
  totalBetMin: number;
  winnings: string;
};

export type SpinResponse = {
  reelOffset: never[];
};

export enum Types {
  init = "INITIALIZE",
  login = "LOGIN",
  getBalance = "GET_BALANCE",
  doInit = "DO_INIT",
  doSpin = "DO_SPIN",
  logout = "LOGOUT",
  setJwt = "SET_JWT",
  setPlayerId = "SET_PLAYERID",
  setUserName = "SET_USERNAME",
  setRefreshToken = "SET_REFRESHTOKEN",
  setSessionKey = "SET_SESSION_KEY",
  setClient = "SET_CLIENT",
  setWinnings = "SET_WINNINGS",
  setEntry = "SET_ENTRY",
  setGamesList = "setGamesList",
  setCert = "SET_CERT",
  setShowLoginForm = "SET_SHOW_LOGIN_FORM",
  setReqCounter = "SET_REQ_COUNTER",
  setIsTauriApp = "SET_IS_TAURI_APP",
  setIsMuted = "SET_IS_MUTED",
}

export type AuthAuthPayload = {
  [Types.getBalance]: {
    client: any;
    sessionKey: string;
  };
  [Types.setGamesList]: {
    activeGames: any[];
  };
  [Types.doInit]: undefined;
  [Types.doSpin]: undefined;
  [Types.logout]: undefined;
  [Types.setJwt]: {
    jwt: string;
  };
  [Types.setPlayerId]: {
    playerId: string;
  };

  [Types.setUserName]: {
    username: string;
  };

  [Types.setRefreshToken]: {
    refreshToken: string;
  };
  [Types.setSessionKey]: {
    sessionKey: string;
  };
  [Types.setClient]: {
    client: any;
  };
  [Types.setWinnings]: {
    winnings: string;
  };
  [Types.setEntry]: {
    entry: string;
  };
  [Types.setCert]: {
    cert: string;
  };
  [Types.setShowLoginForm]: {
    showLoginForm: boolean;
  };
  [Types.setReqCounter]: {
    reqCounter: number;
  };
  [Types.setIsTauriApp]: {
    isTauriApp: boolean;
  };
  [Types.setIsMuted]: {
    isMuted: boolean;
  };
};

export type AuthActions =
  ActionMap<AuthAuthPayload>[keyof ActionMap<AuthAuthPayload>];
