import { createContext, ReactNode, useEffect, useReducer } from "react";
import { createPromiseClient, Interceptor } from "@connectrpc/connect";
import { createGrpcWebTransport } from "@connectrpc/connect-web";
import { GameServerService } from "../../.proto/gameserver_connect";
import { AuthActions, Types } from "./AuthTypes";
import { fs, invoke } from "@tauri-apps/api";
import { BaseDirectory } from "@tauri-apps/api/path";
import { Auth } from "../Auth";
import { Buffer } from "buffer";
import * as x509 from "@peculiar/x509";
import { Crypto } from "@peculiar/webcrypto";
import { getVersion } from "@tauri-apps/api/app";
import axios from "axios";

const crypto = new Crypto();
x509.cryptoProvider.set(crypto);
// ----------------------------------------------------------------------

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

type AuthState = {
  playerId: string;
  username: string;
  jwt: string;
  refreshToken: string;
  sessionKey: string;
  client: any;
  winnings: string;
  entry: string;
  activeGames: any[];
  cert: string;
  showLoginForm: boolean;
  reqCounter: number;
  isTauriApp: boolean;
  isMuted: boolean;
};

type LoginRequest = {
  id?: string;
  cardSerialNumber?: string;
  email?: string;
  password?: string;
};

type AuthContextType = {
  playerId: string;
  username: string;
  jwt: string;
  refreshToken: string;
  sessionKey: string;
  activeGames: any[];
  client: any;
  winnings: string;
  entry: string;
  cert: string;
  showLoginForm: boolean;
  reqCounter: number;
  isTauriApp: boolean;
  isMuted: boolean;
  login: (loginRequest: LoginRequest) => void;
  getBalance: () => void;
  doInit: () => Promise<InitResponse | undefined>;
  doSpin: (
    betAmount: number,
    lines: number,
    reelOffset: number[]
  ) => Promise<SpinResponse | undefined>;
  logout: VoidFunction;
  setJwt: (jwt: string) => Promise<void>;
  setPlayerId: (playerId: string) => Promise<void>;
  setPlayerUsername: (username: string) => Promise<void>;
  setRefreshToken: (refreshToken: string) => Promise<void>;
  setSessionKey: (sessionKey: string) => Promise<void>;
  setClient: (client: any) => Promise<void>;
  setWinnings: (winnings: string) => void;
  setEntry: (entry: string) => void;
  setCert: (cert: string) => Promise<void>;
  setShowLoginForm: (showLoginForm: boolean) => void;
  setReqCounter: (reqCounter: number) => void;
  setIsTauriApp: (isTauriApp: boolean) => void;
  setIsMuted: (isMuted: boolean) => void;

  initializeStation: (secret: string) => Promise<void>;
  refreshCertificate: () => Promise<void>;
};

type InitResponse_Paytable = {
  paytable: number[];
  symbol: number;
  type: number;
};

type InitResponse = {
  entry: string;
  lines: number;
  paytable: InitResponse_Paytable;
  reelOffset: number[];
  reelOffset0: string[];
  reelOffset1: string[];
  reelOffset2: string[];
  reelOffset3: string[];
  reelOffset4: string[];
  requestCounter: number;
  totalBetMax: number;
  totalBetMin: number;
  winnings: string;
};

type SpinResponse = {
  reelOffset: never[];
};

// ----------------------------------------------------------------------

const initialState: AuthState = {
  playerId: "",
  username: "",
  jwt: "",
  refreshToken: "",
  sessionKey: "",
  client: null,
  activeGames: [],
  winnings: "",
  entry: "",
  cert: "",
  showLoginForm: false,
  reqCounter: 0,
  isTauriApp: false,
  isMuted: false,
};

const reducer = (state: AuthState, action: AuthActions) => {
  if (action.type === Types.getBalance) {
    const { client, sessionKey } = action.payload;
    return { ...state, client, sessionKey };
  }
  if (action.type === Types.doInit) {
    return state;
  }
  if (action.type === Types.doSpin) {
    return state;
  }
  if (action.type === Types.setGamesList) {
    const { activeGames } = action.payload;
    return { ...state, activeGames };
  }
  if (action.type === Types.logout) {
    return {
      ...state,
      playerId: "",
      username: "",
      jwt: "",
      refreshToken: "",
      sessionKey: "",
      client: null,
      winnings: "",
      entry: "",
      cert: "",
      showLoginForm: false,
      reqCounter: 0,
    };
  }
  if (action.type === Types.setJwt) {
    return {
      ...state,
      jwt: action.payload.jwt,
    };
  }
  if (action.type === Types.setPlayerId) {
    return {
      ...state,
      playerId: action.payload.playerId,
    };
  }
  if (action.type === Types.setUserName) {
    return {
      ...state,
      username: action.payload.username,
    };
  }
  if (action.type === Types.setRefreshToken) {
    return {
      ...state,
      refreshToken: action.payload.refreshToken,
    };
  }
  if (action.type === Types.setSessionKey) {
    return {
      ...state,
      sessionKey: action.payload.sessionKey,
    };
  }
  if (action.type === Types.setClient) {
    return {
      ...state,
      client: action.payload.client,
    };
  }
  if (action.type === Types.setWinnings) {
    return {
      ...state,
      winnings: action.payload.winnings,
    };
  }
  if (action.type === Types.setEntry) {
    return {
      ...state,
      entry: action.payload.entry,
    };
  }
  if (action.type === Types.setCert) {
    return {
      ...state,
      cert: action.payload.cert,
    };
  }
  if (action.type === Types.setShowLoginForm) {
    return {
      ...state,
      showLoginForm: action.payload.showLoginForm,
    };
  }
  if (action.type === Types.setReqCounter) {
    return {
      ...state,
      reqCounter: action.payload.reqCounter,
    };
  }
  if (action.type === Types.setIsTauriApp) {
    return {
      ...state,
      isTauriApp: action.payload.isTauriApp,
    };
  }
  if (action.type === Types.setIsMuted) {
    return {
      ...state,
      isMuted: action.payload.isMuted,
    };
  }

  return state;
};

const AuthContext = createContext<AuthContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

const auth = new Auth();

let grpcClient: any;

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Get the list of active games
  useEffect(() => {
    const getActiveGameList = async () => {
      const gamesListResponseApiCall = axios.get(
        import.meta.env.VITE_APP_API + "/getActiveGameList"
      );
      const gamesListResponse = await gamesListResponseApiCall;
      setGamesList(gamesListResponse.data);
    };
    getActiveGameList();
  }, [window.location.href]);

  useEffect(() => {
    const initialize = async () => {
      try {
        // Check if the app is running in Tauri or on the web
        if (window.__TAURI__) {
          setIsTauriApp(true);
        } else {
          setIsTauriApp(false);
          // Play from home check if the jwt token is still valid
          const jwt = localStorage.getItem("jwt");
          if (jwt) {
            const isStale = auth.isJwtStale(jwt!);
            if (!isStale) {
              const client = createClient(false);
              // Decode the jwt token
              const decodedJwt = decodeJWT(jwt!);
              const playerId = decodedJwt.payload.playerId;
              const playerUsername = decodedJwt.payload.username;
              // Create a session
              const CreateSessionResponse = await client.createSession(
                {
                  stationId: import.meta.env.VITE_APP_STATION_ID!,
                  playerId: playerId,
                },
                {
                  headers: {
                    authorization: `Bearer ${jwt}`,
                    "x-player": playerId,
                  },
                }
              );
              if (CreateSessionResponse?.sessionKey) {
                await setClient(client);
                await setSessionKey(CreateSessionResponse.sessionKey);
                await setJwt(jwt!);
                await setPlayerId(playerId);
                await setPlayerUsername(playerUsername);
                return true;
              }
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    initialize();
  }, []);

  const refreshCertificateInterceptor: Interceptor = (next) => async (req) => {
    if (!req.url.includes("playAtHome")) {
      const shouldRefreshCert = await invoke("should_refresh_certificate");
      if (shouldRefreshCert) {
        await refreshCertificate();
      }
    }
    return await next(req);
  };

  const createClient = (isTauriApp: boolean) => {
    let url;

    if (isTauriApp) {
      // use the Tauri API
      url = import.meta.env.VITE_APP_API;
    } else {
      // use the web API
      url = import.meta.env.VITE_APP_API + "/playAtHome";
    }

    const transport = createGrpcWebTransport({
      baseUrl: url,
      credentials: "same-origin",
      interceptors: [refreshCertificateInterceptor],
    });
    return createPromiseClient(GameServerService, transport);
  };

  const getGrpcClient = (isTauriApp: boolean) => {
    if (!grpcClient) {
      grpcClient = createClient(isTauriApp);
    }
    return grpcClient;
  };

  // ID is the certifcate id if the app is running in Tauri or the email/password if the app is running in the browser
  const login = async (loginInput: LoginRequest) => {
    try {
      const { id, cardSerialNumber, email, password } = loginInput;
      // If the app is running in Tauri, use the Tauri API to login
      if (state.isTauriApp) {
        const client = getGrpcClient(state.isTauriApp);
        // Read the certificate
        const cert = await fs.readBinaryFile("station-cert.pem", {
          dir: BaseDirectory.AppData,
        });
        // Encode the certificate to base64
        const base64Encode = Buffer.from(cert).toString("base64");
        // Get the certificate subject
        const { subject } = new x509.X509Certificate(cert);
        // Parse the certificate subject
        const parsedSubject = parseCertificateSubject(subject);

        // Get station id from the certificate
        const stationId = parsedSubject["CN"];

        // Get certificate signature
        const message = `${id}-${stationId}`;
        const base64EncodedMessage = Buffer.from(message).toString("base64");
        const signature = await invoke("get_signature", {
          base64Message: base64EncodedMessage,
        });

        // Create a session
        const res = await client.createSession(
          {
            stationId: stationId,
            playerId: id,
          },
          {
            headers: {
              "x-client-cert": base64Encode,
              "x-client-signature": signature,
              "x-player": id,
              "x-card-number": cardSerialNumber!,
            } as any,
          }
        );

        if (res?.sessionKey) {
          await setClient(client);
          await setCert(base64Encode);
          await setPlayerId(id);
          await setPlayerUsername(res.username);
          await setSessionKey(res.sessionKey);
          return true;
        } else {
          return false;
        }
      }
      // If the app is running in the browser, use the web API to login
      else {
        const data = await auth.login(email!, password!);
        if (data?.token) {
          // No refresh token needed for web login ?

          // Create a client
          const client = getGrpcClient(state.isTauriApp);

          // Decode the jwt token
          const jwt = data.token.split(".")[1];
          const decodedJwt = JSON.parse(atob(jwt));
          const playerId = decodedJwt.playerId;
          const playerUsername = decodedJwt.username;
          // Create a session
          const CreateSessionResponse = await client.createSession(
            {
              stationId: parseInt(import.meta.env.VITE_APP_STATION_ID!),
              playerId: playerId,
            },
            {
              headers: {
                authorization: `Bearer ${data.token}`,
                "x-player": playerId,
              },
            }
          );

          if (CreateSessionResponse?.sessionKey) {
            await setClient(client);
            await setJwt(data.token);
            await setPlayerId(playerId);
            await setPlayerUsername(playerUsername);
            await setSessionKey(CreateSessionResponse.sessionKey);
            return true;
          }
        } else {
          return false;
        }
      }
    } catch (error) {
      console.error(error);
      await logout();
      return error;
    }
  };

  const logout = async () => {
    try {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("jwt");
      dispatch({ type: Types.logout });
    } catch (error) {
      console.error(error);
    }
  };

  const getBalance = async () => {
    if (state.client) {
      if (state.isTauriApp) {
        try {
          const getBalanceResponse = await state.client.getBalance(
            {
              sessionKey: state.sessionKey,
            },
            {
              headers: {
                "x-client-cert": state.cert,
                "x-player": state.playerId,
              },
            }
          );
          if (getBalanceResponse?.entry) {
            await setEntry(getBalanceResponse.entry);
          }
          if (getBalanceResponse?.winnings) {
            await setWinnings(getBalanceResponse.winnings);
          }
          if (getBalanceResponse?.requestCount) {
            await setReqCounter(getBalanceResponse.requestCount);
          }
        } catch (error) {
          console.error("error", error);
        }
      } else {
        try {
          const getBalanceResponse = await state.client.getBalance(
            {
              sessionKey: state.sessionKey,
            },
            {
              headers: {
                authorization: `Bearer ${state.jwt}`,
                "x-player": state.playerId,
              },
            }
          );
          if (getBalanceResponse?.entry) {
            await setEntry(getBalanceResponse.entry);
          }
          if (getBalanceResponse?.winnings) {
            await setWinnings(getBalanceResponse.winnings);
          }
          if (getBalanceResponse?.requestCount) {
            await setReqCounter(getBalanceResponse.requestCount);
          }
        } catch (error) {
          console.error("error", error);
        }
      }
    }
  };

  const doInit = async () => {
    if (state.client) {
      const headers: {
        [key: string]: string;
      } = {
        "x-player": state.playerId,
      };
      if (state.isTauriApp) {
        headers["x-client-cert"] = state.cert;
      } else {
        headers["authorization"] = `Bearer ${state.jwt}`;
      }
      return await state.client.doInit(
        {
          sessionKey: state.sessionKey,
        },
        {
          headers,
        }
      );
    } else {
      throw new Error("No client found");
    }
  };

  const doSpin = async (
    betAmount: number,
    lines: number,
    reelOffset: number[]
  ) => {
    if (state.client) {
      if (state.isTauriApp) {
        return await state.client.doSpin(
          {
            sessionKey: state.sessionKey,
            gameSymbol: "",
            reelOffset: reelOffset,
            betAmount: betAmount,
            lines: lines,
            reqCounter: state.reqCounter,
          },
          {
            headers: {
              "x-client-cert": state.cert,
              "x-player": state.playerId,
            },
          }
        );
      } else {
        await setReqCounter(state.reqCounter + 1);
        return await state.client.doSpin(
          {
            sessionKey: state.sessionKey,
            gameSymbol: "",
            reelOffset: reelOffset,
            betAmount: betAmount,
            lines: lines,
            reqCounter: state.reqCounter,
          },
          {
            headers: {
              authorization: `Bearer ${state.jwt}`,
              "x-player": state.playerId,
            },
          }
        );
      }
    }
  };

  const initializeStation = async (secret: string) => {
    if (state.isTauriApp) {
      const stationData = await auth.getStationData(secret);
      if (stationData == null) {
        throw new Error("Failed to get station data");
      }

      await invoke("generate_certificate", {
        roomId: stationData.roomId,
        stationId: stationData.id,
      });

      const certificate = await fs.readBinaryFile("station-cert.pem", {
        dir: BaseDirectory.AppData,
      });

      // const stationAppVersion = await invoke("get_app_version");
      // if (!stationAppVersion) {
      //   throw new Error("Failed to get station app version");
      // }
      const stationAppVersion = await getVersion();

      await auth.initializeStation(
        secret,
        Buffer.from(certificate).toString("base64"),
        stationAppVersion as string
      );
    }
  };

  // TODO: figure out when or where to call this function
  const refreshCertificate = async () => {
    if (state.isTauriApp) {
      const oldCertificate = await fs.readBinaryFile("station-cert.pem", {
        dir: BaseDirectory.AppData,
      });

      await invoke("refresh_certificate");

      const newCert = await fs.readBinaryFile("station-cert.pem", {
        dir: BaseDirectory.AppData,
      });

      await auth.refreshCertificate(
        Buffer.from(newCert).toString("base64"),
        Buffer.from(oldCertificate).toString("base64")
      );

      setCert(Buffer.from(newCert).toString("base64"));
    }
  };

  const setJwt = async (jwt: any) => {
    localStorage.setItem("jwt", jwt.toString());
    dispatch({ type: Types.setJwt, payload: { jwt: jwt } });
  };

  const setPlayerId = async (playerId: any) => {
    dispatch({ type: Types.setPlayerId, payload: { playerId: playerId } });
  };

  const setPlayerUsername = async (username: any) => {
    dispatch({
      type: Types.setUserName,
      payload: { username: username },
    });
  };

  const setRefreshToken = async (refreshToken: any) => {
    localStorage.setItem("refreshToken", refreshToken.toString());
    dispatch({
      type: Types.setRefreshToken,
      payload: { refreshToken: refreshToken },
    });
  };

  const setSessionKey = async (sessionKey: any) => {
    dispatch({
      type: Types.setSessionKey,
      payload: { sessionKey: sessionKey },
    });
  };

  const setClient = async (client: any) => {
    dispatch({
      type: Types.setClient,
      payload: { client: client },
    });
  };

  const setWinnings = async (winnings: any) => {
    dispatch({
      type: Types.setWinnings,
      payload: { winnings: winnings },
    });
  };

  const setEntry = async (entry: any) => {
    dispatch({
      type: Types.setEntry,
      payload: { entry: entry },
    });
  };

  const setCert = async (cert: any) => {
    dispatch({ type: Types.setCert, payload: { cert: cert } });
  };

  const setShowLoginForm = async (showLoginForm: any) => {
    dispatch({
      type: Types.setShowLoginForm,
      payload: { showLoginForm: showLoginForm },
    });
  };

  const setReqCounter = async (reqCounter: any) => {
    dispatch({
      type: Types.setReqCounter,
      payload: { reqCounter: reqCounter },
    });
  };

  const setIsTauriApp = async (isTauriApp: any) => {
    dispatch({
      type: Types.setIsTauriApp,
      payload: { isTauriApp: isTauriApp },
    });
  };

  const setIsMuted = async (isMuted: any) => {
    dispatch({
      type: Types.setIsMuted,
      payload: { isMuted: isMuted },
    });
  };

  const parseCertificateSubject = (subject: string) => {
    const keyValuePairs = subject
      .split(",")
      .map((pair) => pair.trim().split("="));
    const subjectObject = keyValuePairs.reduce(
      (acc: { [key: string]: string }, [key, value]: any) => {
        acc[key] = value;
        return acc;
      },
      {}
    );
    return subjectObject;
  };

  const setGamesList = async (activeGames: any) => {
    dispatch({
      type: Types.setGamesList,
      payload: { activeGames: activeGames },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        playerId: state?.playerId,
        username: state?.username,
        jwt: state?.jwt,
        refreshToken: state?.refreshToken,
        sessionKey: state?.sessionKey,
        client: state?.client,
        winnings: state?.winnings,
        entry: state?.entry,
        activeGames: state?.activeGames,
        cert: state?.cert,
        showLoginForm: state?.showLoginForm,
        reqCounter: state?.reqCounter,
        isTauriApp: state?.isTauriApp,
        isMuted: state?.isMuted,
        login,
        getBalance,
        doInit,
        doSpin,
        logout,
        setJwt,
        setPlayerId,
        setPlayerUsername,
        setRefreshToken,
        setSessionKey,
        setClient,
        setWinnings,
        setEntry,
        setCert,
        setShowLoginForm,
        setReqCounter,
        setIsTauriApp,
        setIsMuted,

        initializeStation,
        refreshCertificate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

// Function to decode JWT
function base64urlDecode(str: string) {
  return decodeURIComponent(
    atob(str.replace(/_/g, "/").replace(/-/g, "+"))
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

function decodeJWT(token: string) {
  const parts = token.split(".");
  if (parts.length !== 3) {
    throw new Error("Invalid JWT token");
  }

  const header = JSON.parse(base64urlDecode(parts[0]));
  const payload = JSON.parse(base64urlDecode(parts[1]));

  return {
    header: header,
    payload: payload,
    signature: parts[2],
  };
}
