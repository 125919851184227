// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts,import_extension=none"
// @generated from file gameserver.proto (syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BalanceRequest, BalanceResponse, BalanceStreamResponse, BetCrashRequest, BetCrashResponse, BonusGameRequest, BonusGameResponse, CashoutCrashRequest, CashoutCrashResponse, CrashStreamResponse, CreateSessionRequest, CreateSessionResponse, EndSessionRequest, EndSessionResponse, FlipRequest, FlipResponse, InitCrashRequest, InitCrashResponse, InitSlotsRequest, InitSlotsResponse, SpinRequest, SpinResponse } from "./gameserver_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service GameServerService
 */
export const GameServerService = {
  typeName: "GameServerService",
  methods: {
    /**
     * @generated from rpc GameServerService.createSession
     */
    createSession: {
      name: "createSession",
      I: CreateSessionRequest,
      O: CreateSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc GameServerService.getBalance
     */
    getBalance: {
      name: "getBalance",
      I: BalanceRequest,
      O: BalanceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc GameServerService.streamBalance
     */
    streamBalance: {
      name: "streamBalance",
      I: BalanceRequest,
      O: BalanceStreamResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc GameServerService.doInitSlots
     */
    doInitSlots: {
      name: "doInitSlots",
      I: InitSlotsRequest,
      O: InitSlotsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc GameServerService.doInitCrash
     */
    doInitCrash: {
      name: "doInitCrash",
      I: InitCrashRequest,
      O: InitCrashResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc GameServerService.doSpin
     */
    doSpin: {
      name: "doSpin",
      I: SpinRequest,
      O: SpinResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc GameServerService.doBetCrash
     */
    doBetCrash: {
      name: "doBetCrash",
      I: BetCrashRequest,
      O: BetCrashResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc GameServerService.doCashoutCrash
     */
    doCashoutCrash: {
      name: "doCashoutCrash",
      I: CashoutCrashRequest,
      O: CashoutCrashResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc GameServerService.streamCrash
     */
    streamCrash: {
      name: "streamCrash",
      I: InitCrashRequest,
      O: CrashStreamResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc GameServerService.doFlip
     */
    doFlip: {
      name: "doFlip",
      I: FlipRequest,
      O: FlipResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc GameServerService.doBonusGame
     */
    doBonusGame: {
      name: "doBonusGame",
      I: BonusGameRequest,
      O: BonusGameResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc GameServerService.doEndSession
     */
    doEndSession: {
      name: "doEndSession",
      I: EndSessionRequest,
      O: EndSessionResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

