// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file gameserver.proto (syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum PaySymbolType
 */
export enum PaySymbolType {
  /**
   * @generated from enum value: Normal = 0;
   */
  Normal = 0,

  /**
   * @generated from enum value: FreeSpin = 1;
   */
  FreeSpin = 1,

  /**
   * @generated from enum value: Wild = 2;
   */
  Wild = 2,

  /**
   * @generated from enum value: Bonus = 3;
   */
  Bonus = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(PaySymbolType)
proto3.util.setEnumType(PaySymbolType, "PaySymbolType", [
  { no: 0, name: "Normal" },
  { no: 1, name: "FreeSpin" },
  { no: 2, name: "Wild" },
  { no: 3, name: "Bonus" },
]);

/**
 * ----------------------- COMMON ------------------------------
 *
 * @generated from message CreateSessionRequest
 */
export class CreateSessionRequest extends Message<CreateSessionRequest> {
  /**
   * @generated from field: string player_id = 1;
   */
  playerId = "";

  /**
   * @generated from field: string station_id = 2;
   */
  stationId = "";

  constructor(data?: PartialMessage<CreateSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "CreateSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "station_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSessionRequest {
    return new CreateSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSessionRequest {
    return new CreateSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSessionRequest {
    return new CreateSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSessionRequest | PlainMessage<CreateSessionRequest> | undefined, b: CreateSessionRequest | PlainMessage<CreateSessionRequest> | undefined): boolean {
    return proto3.util.equals(CreateSessionRequest, a, b);
  }
}

/**
 * @generated from message CreateSessionResponse
 */
export class CreateSessionResponse extends Message<CreateSessionResponse> {
  /**
   * @generated from field: string session_key = 1;
   */
  sessionKey = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  constructor(data?: PartialMessage<CreateSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "CreateSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSessionResponse {
    return new CreateSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSessionResponse {
    return new CreateSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSessionResponse {
    return new CreateSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSessionResponse | PlainMessage<CreateSessionResponse> | undefined, b: CreateSessionResponse | PlainMessage<CreateSessionResponse> | undefined): boolean {
    return proto3.util.equals(CreateSessionResponse, a, b);
  }
}

/**
 * @generated from message BalanceRequest
 */
export class BalanceRequest extends Message<BalanceRequest> {
  /**
   * @generated from field: string session_key = 1;
   */
  sessionKey = "";

  constructor(data?: PartialMessage<BalanceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "BalanceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BalanceRequest {
    return new BalanceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BalanceRequest {
    return new BalanceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BalanceRequest {
    return new BalanceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BalanceRequest | PlainMessage<BalanceRequest> | undefined, b: BalanceRequest | PlainMessage<BalanceRequest> | undefined): boolean {
    return proto3.util.equals(BalanceRequest, a, b);
  }
}

/**
 * @generated from message BalanceResponse
 */
export class BalanceResponse extends Message<BalanceResponse> {
  /**
   * @generated from field: string entry = 1;
   */
  entry = "";

  /**
   * @generated from field: string winnings = 2;
   */
  winnings = "";

  /**
   * @generated from field: int32 requestCount = 3;
   */
  requestCount = 0;

  constructor(data?: PartialMessage<BalanceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "BalanceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entry", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "winnings", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "requestCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BalanceResponse {
    return new BalanceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BalanceResponse {
    return new BalanceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BalanceResponse {
    return new BalanceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BalanceResponse | PlainMessage<BalanceResponse> | undefined, b: BalanceResponse | PlainMessage<BalanceResponse> | undefined): boolean {
    return proto3.util.equals(BalanceResponse, a, b);
  }
}

/**
 * @generated from message BalanceStreamResponse
 */
export class BalanceStreamResponse extends Message<BalanceStreamResponse> {
  /**
   * @generated from field: string entry = 1;
   */
  entry = "";

  /**
   * @generated from field: string winnings = 2;
   */
  winnings = "";

  constructor(data?: PartialMessage<BalanceStreamResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "BalanceStreamResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entry", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "winnings", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BalanceStreamResponse {
    return new BalanceStreamResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BalanceStreamResponse {
    return new BalanceStreamResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BalanceStreamResponse {
    return new BalanceStreamResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BalanceStreamResponse | PlainMessage<BalanceStreamResponse> | undefined, b: BalanceStreamResponse | PlainMessage<BalanceStreamResponse> | undefined): boolean {
    return proto3.util.equals(BalanceStreamResponse, a, b);
  }
}

/**
 * @generated from message EndSessionRequest
 */
export class EndSessionRequest extends Message<EndSessionRequest> {
  /**
   * @generated from field: string session_key = 1;
   */
  sessionKey = "";

  /**
   * @generated from field: int32 req_counter = 2;
   */
  reqCounter = 0;

  /**
   * @generated from field: int32 win_percent = 3;
   */
  winPercent = 0;

  /**
   * @generated from field: int32 total_winnings_cashout = 4;
   */
  totalWinningsCashout = 0;

  constructor(data?: PartialMessage<EndSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "EndSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "req_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "win_percent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "total_winnings_cashout", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EndSessionRequest {
    return new EndSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EndSessionRequest {
    return new EndSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EndSessionRequest {
    return new EndSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EndSessionRequest | PlainMessage<EndSessionRequest> | undefined, b: EndSessionRequest | PlainMessage<EndSessionRequest> | undefined): boolean {
    return proto3.util.equals(EndSessionRequest, a, b);
  }
}

/**
 * @generated from message EndSessionResponse
 */
export class EndSessionResponse extends Message<EndSessionResponse> {
  /**
   * @generated from field: string entry = 1;
   */
  entry = "";

  /**
   * @generated from field: string winnings = 2;
   */
  winnings = "";

  /**
   * @generated from field: int32 req_counter = 3;
   */
  reqCounter = 0;

  /**
   * @generated from field: int32 total_cashout_game = 4;
   */
  totalCashoutGame = 0;

  /**
   * @generated from field: int32 total_cashout = 5;
   */
  totalCashout = 0;

  constructor(data?: PartialMessage<EndSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "EndSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entry", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "winnings", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "req_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "total_cashout_game", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "total_cashout", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EndSessionResponse {
    return new EndSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EndSessionResponse {
    return new EndSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EndSessionResponse {
    return new EndSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EndSessionResponse | PlainMessage<EndSessionResponse> | undefined, b: EndSessionResponse | PlainMessage<EndSessionResponse> | undefined): boolean {
    return proto3.util.equals(EndSessionResponse, a, b);
  }
}

/**
 * ----------------------- SLOTS ------------------------------
 *
 * @generated from message InitSlotsRequest
 */
export class InitSlotsRequest extends Message<InitSlotsRequest> {
  /**
   * @generated from field: string game_symbol = 1;
   */
  gameSymbol = "";

  /**
   * int32 index = 2;
   * int32 counter = 3;
   *
   * @generated from field: string session_key = 2;
   */
  sessionKey = "";

  constructor(data?: PartialMessage<InitSlotsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "InitSlotsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "game_symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "session_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitSlotsRequest {
    return new InitSlotsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitSlotsRequest {
    return new InitSlotsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitSlotsRequest {
    return new InitSlotsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InitSlotsRequest | PlainMessage<InitSlotsRequest> | undefined, b: InitSlotsRequest | PlainMessage<InitSlotsRequest> | undefined): boolean {
    return proto3.util.equals(InitSlotsRequest, a, b);
  }
}

/**
 * @generated from message InitSlotsResponse
 */
export class InitSlotsResponse extends Message<InitSlotsResponse> {
  /**
   * @generated from field: string entry = 1;
   */
  entry = "";

  /**
   * @generated from field: string winnings = 2;
   */
  winnings = "";

  /**
   * int32 index = 2;
   * int32 reel_set_size = 3;
   *
   * @generated from field: int32 request_counter = 3;
   */
  requestCounter = 0;

  /**
   * @generated from field: repeated InitSlotsResponse.PayTable payTable = 4;
   */
  payTable: InitSlotsResponse_PayTable[] = [];

  /**
   * @generated from field: repeated InitSlotsResponse.PayLine payLines = 5;
   */
  payLines: InitSlotsResponse_PayLine[] = [];

  /**
   * @generated from field: int32 lines = 6;
   */
  lines = 0;

  /**
   * @generated from field: repeated int32 reel_offset = 7;
   */
  reelOffset: number[] = [];

  /**
   * @generated from field: double total_bet_max = 8;
   */
  totalBetMax = 0;

  /**
   * @generated from field: double total_bet_min = 9;
   */
  totalBetMin = 0;

  /**
   * @generated from field: repeated string reel_set0 = 10;
   */
  reelSet0: string[] = [];

  /**
   * @generated from field: repeated string reel_set1 = 11;
   */
  reelSet1: string[] = [];

  /**
   * @generated from field: repeated string reel_set2 = 12;
   */
  reelSet2: string[] = [];

  /**
   * @generated from field: repeated string reel_set3 = 13;
   */
  reelSet3: string[] = [];

  /**
   * @generated from field: repeated string reel_set4 = 14;
   */
  reelSet4: string[] = [];

  constructor(data?: PartialMessage<InitSlotsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "InitSlotsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entry", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "winnings", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "request_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "payTable", kind: "message", T: InitSlotsResponse_PayTable, repeated: true },
    { no: 5, name: "payLines", kind: "message", T: InitSlotsResponse_PayLine, repeated: true },
    { no: 6, name: "lines", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "reel_offset", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 8, name: "total_bet_max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "total_bet_min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "reel_set0", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "reel_set1", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "reel_set2", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "reel_set3", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "reel_set4", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitSlotsResponse {
    return new InitSlotsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitSlotsResponse {
    return new InitSlotsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitSlotsResponse {
    return new InitSlotsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitSlotsResponse | PlainMessage<InitSlotsResponse> | undefined, b: InitSlotsResponse | PlainMessage<InitSlotsResponse> | undefined): boolean {
    return proto3.util.equals(InitSlotsResponse, a, b);
  }
}

/**
 * @generated from message InitSlotsResponse.PayTable
 */
export class InitSlotsResponse_PayTable extends Message<InitSlotsResponse_PayTable> {
  /**
   * @generated from field: int32 symbol = 1;
   */
  symbol = 0;

  /**
   * @generated from field: repeated int32 payTable = 2;
   */
  payTable: number[] = [];

  /**
   * @generated from field: PaySymbolType type = 3;
   */
  type = PaySymbolType.Normal;

  constructor(data?: PartialMessage<InitSlotsResponse_PayTable>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "InitSlotsResponse.PayTable";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "symbol", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "payTable", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(PaySymbolType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitSlotsResponse_PayTable {
    return new InitSlotsResponse_PayTable().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitSlotsResponse_PayTable {
    return new InitSlotsResponse_PayTable().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitSlotsResponse_PayTable {
    return new InitSlotsResponse_PayTable().fromJsonString(jsonString, options);
  }

  static equals(a: InitSlotsResponse_PayTable | PlainMessage<InitSlotsResponse_PayTable> | undefined, b: InitSlotsResponse_PayTable | PlainMessage<InitSlotsResponse_PayTable> | undefined): boolean {
    return proto3.util.equals(InitSlotsResponse_PayTable, a, b);
  }
}

/**
 * @generated from message InitSlotsResponse.PayPosition
 */
export class InitSlotsResponse_PayPosition extends Message<InitSlotsResponse_PayPosition> {
  /**
   * @generated from field: int32 x = 1;
   */
  x = 0;

  /**
   * @generated from field: int32 y = 2;
   */
  y = 0;

  constructor(data?: PartialMessage<InitSlotsResponse_PayPosition>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "InitSlotsResponse.PayPosition";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "x", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "y", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitSlotsResponse_PayPosition {
    return new InitSlotsResponse_PayPosition().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitSlotsResponse_PayPosition {
    return new InitSlotsResponse_PayPosition().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitSlotsResponse_PayPosition {
    return new InitSlotsResponse_PayPosition().fromJsonString(jsonString, options);
  }

  static equals(a: InitSlotsResponse_PayPosition | PlainMessage<InitSlotsResponse_PayPosition> | undefined, b: InitSlotsResponse_PayPosition | PlainMessage<InitSlotsResponse_PayPosition> | undefined): boolean {
    return proto3.util.equals(InitSlotsResponse_PayPosition, a, b);
  }
}

/**
 * @generated from message InitSlotsResponse.PayLine
 */
export class InitSlotsResponse_PayLine extends Message<InitSlotsResponse_PayLine> {
  /**
   * @generated from field: repeated InitSlotsResponse.PayPosition payLine = 1;
   */
  payLine: InitSlotsResponse_PayPosition[] = [];

  constructor(data?: PartialMessage<InitSlotsResponse_PayLine>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "InitSlotsResponse.PayLine";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "payLine", kind: "message", T: InitSlotsResponse_PayPosition, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitSlotsResponse_PayLine {
    return new InitSlotsResponse_PayLine().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitSlotsResponse_PayLine {
    return new InitSlotsResponse_PayLine().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitSlotsResponse_PayLine {
    return new InitSlotsResponse_PayLine().fromJsonString(jsonString, options);
  }

  static equals(a: InitSlotsResponse_PayLine | PlainMessage<InitSlotsResponse_PayLine> | undefined, b: InitSlotsResponse_PayLine | PlainMessage<InitSlotsResponse_PayLine> | undefined): boolean {
    return proto3.util.equals(InitSlotsResponse_PayLine, a, b);
  }
}

/**
 * @generated from message SpinRequest
 */
export class SpinRequest extends Message<SpinRequest> {
  /**
   * @generated from field: string game_symbol = 1;
   */
  gameSymbol = "";

  /**
   * @generated from field: int32 bet_amount = 2;
   */
  betAmount = 0;

  /**
   * @generated from field: int32 lines = 3;
   */
  lines = 0;

  /**
   * int32 index = 4;
   *
   * @generated from field: int32 req_counter = 4;
   */
  reqCounter = 0;

  /**
   * @generated from field: repeated int32 reel_offset = 5;
   */
  reelOffset: number[] = [];

  /**
   * @generated from field: string session_key = 6;
   */
  sessionKey = "";

  constructor(data?: PartialMessage<SpinRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "SpinRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "game_symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bet_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "lines", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "req_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "reel_offset", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 6, name: "session_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SpinRequest {
    return new SpinRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SpinRequest {
    return new SpinRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SpinRequest {
    return new SpinRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SpinRequest | PlainMessage<SpinRequest> | undefined, b: SpinRequest | PlainMessage<SpinRequest> | undefined): boolean {
    return proto3.util.equals(SpinRequest, a, b);
  }
}

/**
 * @generated from message SpinResponse
 */
export class SpinResponse extends Message<SpinResponse> {
  /**
   * @generated from field: string entry = 1;
   */
  entry = "";

  /**
   * @generated from field: repeated string reel_set = 2;
   */
  reelSet: string[] = [];

  /**
   * @generated from field: repeated SpinResponse.Win win = 3;
   */
  win: SpinResponse_Win[] = [];

  /**
   * @generated from field: repeated int32 reel_offset = 4;
   */
  reelOffset: number[] = [];

  /**
   * @generated from field: int32 req_counter = 5;
   */
  reqCounter = 0;

  /**
   * @generated from field: repeated SpinResponse.freeSpin free_spin = 6;
   */
  freeSpin: SpinResponse_freeSpin[] = [];

  /**
   * bonus games
   *
   * @generated from field: int32 bonus_game_count = 7;
   */
  bonusGameCount = 0;

  /**
   * @generated from field: string winnings = 8;
   */
  winnings = "";

  constructor(data?: PartialMessage<SpinResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "SpinResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entry", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "reel_set", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "win", kind: "message", T: SpinResponse_Win, repeated: true },
    { no: 4, name: "reel_offset", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 5, name: "req_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "free_spin", kind: "message", T: SpinResponse_freeSpin, repeated: true },
    { no: 7, name: "bonus_game_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "winnings", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SpinResponse {
    return new SpinResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SpinResponse {
    return new SpinResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SpinResponse {
    return new SpinResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SpinResponse | PlainMessage<SpinResponse> | undefined, b: SpinResponse | PlainMessage<SpinResponse> | undefined): boolean {
    return proto3.util.equals(SpinResponse, a, b);
  }
}

/**
 * @generated from message SpinResponse.Win
 */
export class SpinResponse_Win extends Message<SpinResponse_Win> {
  /**
   * @generated from field: int32 line = 1;
   */
  line = 0;

  /**
   * @generated from field: int32 symbol = 2;
   */
  symbol = 0;

  /**
   * @generated from field: int32 amount_win = 3;
   */
  amountWin = 0;

  /**
   * @generated from field: repeated int32 win_positions = 4;
   */
  winPositions: number[] = [];

  constructor(data?: PartialMessage<SpinResponse_Win>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "SpinResponse.Win";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "line", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "symbol", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "amount_win", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "win_positions", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SpinResponse_Win {
    return new SpinResponse_Win().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SpinResponse_Win {
    return new SpinResponse_Win().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SpinResponse_Win {
    return new SpinResponse_Win().fromJsonString(jsonString, options);
  }

  static equals(a: SpinResponse_Win | PlainMessage<SpinResponse_Win> | undefined, b: SpinResponse_Win | PlainMessage<SpinResponse_Win> | undefined): boolean {
    return proto3.util.equals(SpinResponse_Win, a, b);
  }
}

/**
 * free spins
 *
 * @generated from message SpinResponse.freeSpin
 */
export class SpinResponse_freeSpin extends Message<SpinResponse_freeSpin> {
  /**
   * @generated from field: repeated string reel_set = 2;
   */
  reelSet: string[] = [];

  /**
   * @generated from field: repeated SpinResponse.Win win = 3;
   */
  win: SpinResponse_Win[] = [];

  constructor(data?: PartialMessage<SpinResponse_freeSpin>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "SpinResponse.freeSpin";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "reel_set", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "win", kind: "message", T: SpinResponse_Win, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SpinResponse_freeSpin {
    return new SpinResponse_freeSpin().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SpinResponse_freeSpin {
    return new SpinResponse_freeSpin().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SpinResponse_freeSpin {
    return new SpinResponse_freeSpin().fromJsonString(jsonString, options);
  }

  static equals(a: SpinResponse_freeSpin | PlainMessage<SpinResponse_freeSpin> | undefined, b: SpinResponse_freeSpin | PlainMessage<SpinResponse_freeSpin> | undefined): boolean {
    return proto3.util.equals(SpinResponse_freeSpin, a, b);
  }
}

/**
 * @generated from message BonusGameRequest
 */
export class BonusGameRequest extends Message<BonusGameRequest> {
  /**
   * @generated from field: string session_key = 1;
   */
  sessionKey = "";

  /**
   * @generated from field: int32 req_counter = 2;
   */
  reqCounter = 0;

  /**
   * @generated from field: int32 selected = 3;
   */
  selected = 0;

  /**
   * @generated from field: string game_symbol = 4;
   */
  gameSymbol = "";

  constructor(data?: PartialMessage<BonusGameRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "BonusGameRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "req_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "selected", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "game_symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BonusGameRequest {
    return new BonusGameRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BonusGameRequest {
    return new BonusGameRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BonusGameRequest {
    return new BonusGameRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BonusGameRequest | PlainMessage<BonusGameRequest> | undefined, b: BonusGameRequest | PlainMessage<BonusGameRequest> | undefined): boolean {
    return proto3.util.equals(BonusGameRequest, a, b);
  }
}

/**
 * @generated from message BonusGameResponse
 */
export class BonusGameResponse extends Message<BonusGameResponse> {
  /**
   * @generated from field: string winnings = 1;
   */
  winnings = "";

  /**
   * @generated from field: int32 req_counter = 2;
   */
  reqCounter = 0;

  /**
   * @generated from field: int32 bonus_game_count = 3;
   */
  bonusGameCount = 0;

  constructor(data?: PartialMessage<BonusGameResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "BonusGameResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "winnings", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "req_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "bonus_game_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BonusGameResponse {
    return new BonusGameResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BonusGameResponse {
    return new BonusGameResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BonusGameResponse {
    return new BonusGameResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BonusGameResponse | PlainMessage<BonusGameResponse> | undefined, b: BonusGameResponse | PlainMessage<BonusGameResponse> | undefined): boolean {
    return proto3.util.equals(BonusGameResponse, a, b);
  }
}

/**
 * ----------------------- CRASH ------------------------------
 *
 * @generated from message InitCrashRequest
 */
export class InitCrashRequest extends Message<InitCrashRequest> {
  /**
   * @generated from field: string session_key = 1;
   */
  sessionKey = "";

  constructor(data?: PartialMessage<InitCrashRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "InitCrashRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitCrashRequest {
    return new InitCrashRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitCrashRequest {
    return new InitCrashRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitCrashRequest {
    return new InitCrashRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InitCrashRequest | PlainMessage<InitCrashRequest> | undefined, b: InitCrashRequest | PlainMessage<InitCrashRequest> | undefined): boolean {
    return proto3.util.equals(InitCrashRequest, a, b);
  }
}

/**
 * @generated from message InitCrashResponse
 */
export class InitCrashResponse extends Message<InitCrashResponse> {
  /**
   * @generated from field: string entry = 1;
   */
  entry = "";

  /**
   * @generated from field: string winnings = 2;
   */
  winnings = "";

  /**
   * @generated from field: int32 request_counter = 3;
   */
  requestCounter = 0;

  /**
   * @generated from field: int32 min_bet = 4;
   */
  minBet = 0;

  /**
   * @generated from field: int32 max_bet = 5;
   */
  maxBet = 0;

  constructor(data?: PartialMessage<InitCrashResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "InitCrashResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entry", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "winnings", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "request_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "min_bet", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "max_bet", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitCrashResponse {
    return new InitCrashResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitCrashResponse {
    return new InitCrashResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitCrashResponse {
    return new InitCrashResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitCrashResponse | PlainMessage<InitCrashResponse> | undefined, b: InitCrashResponse | PlainMessage<InitCrashResponse> | undefined): boolean {
    return proto3.util.equals(InitCrashResponse, a, b);
  }
}

/**
 * @generated from message CrashStreamResponse
 */
export class CrashStreamResponse extends Message<CrashStreamResponse> {
  /**
   * @generated from field: repeated CrashStreamResponse.Tx cashed_in = 1;
   */
  cashedIn: CrashStreamResponse_Tx[] = [];

  /**
   * @generated from field: repeated CrashStreamResponse.Tx cashed_out = 2;
   */
  cashedOut: CrashStreamResponse_Tx[] = [];

  /**
   * @generated from field: optional int32 crashpoint = 3;
   */
  crashpoint?: number;

  /**
   * @generated from field: optional int32 multiplier = 4;
   */
  multiplier?: number;

  /**
   * @generated from field: int32 next_round_in = 5;
   */
  nextRoundIn = 0;

  /**
   * @generated from field: int32 time_elapsed = 6;
   */
  timeElapsed = 0;

  /**
   * @generated from field: CrashStreamResponse.Status status = 7;
   */
  status = CrashStreamResponse_Status.STARTING;

  constructor(data?: PartialMessage<CrashStreamResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "CrashStreamResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cashed_in", kind: "message", T: CrashStreamResponse_Tx, repeated: true },
    { no: 2, name: "cashed_out", kind: "message", T: CrashStreamResponse_Tx, repeated: true },
    { no: 3, name: "crashpoint", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "multiplier", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "next_round_in", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "time_elapsed", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(CrashStreamResponse_Status) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CrashStreamResponse {
    return new CrashStreamResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CrashStreamResponse {
    return new CrashStreamResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CrashStreamResponse {
    return new CrashStreamResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CrashStreamResponse | PlainMessage<CrashStreamResponse> | undefined, b: CrashStreamResponse | PlainMessage<CrashStreamResponse> | undefined): boolean {
    return proto3.util.equals(CrashStreamResponse, a, b);
  }
}

/**
 * @generated from enum CrashStreamResponse.Status
 */
export enum CrashStreamResponse_Status {
  /**
   * @generated from enum value: STARTING = 0;
   */
  STARTING = 0,

  /**
   * @generated from enum value: IN_PROGRESS = 1;
   */
  IN_PROGRESS = 1,

  /**
   * @generated from enum value: CRASH = 2;
   */
  CRASH = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(CrashStreamResponse_Status)
proto3.util.setEnumType(CrashStreamResponse_Status, "CrashStreamResponse.Status", [
  { no: 0, name: "STARTING" },
  { no: 1, name: "IN_PROGRESS" },
  { no: 2, name: "CRASH" },
]);

/**
 * @generated from message CrashStreamResponse.Tx
 */
export class CrashStreamResponse_Tx extends Message<CrashStreamResponse_Tx> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string entry = 2;
   */
  entry = "";

  constructor(data?: PartialMessage<CrashStreamResponse_Tx>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "CrashStreamResponse.Tx";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entry", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CrashStreamResponse_Tx {
    return new CrashStreamResponse_Tx().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CrashStreamResponse_Tx {
    return new CrashStreamResponse_Tx().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CrashStreamResponse_Tx {
    return new CrashStreamResponse_Tx().fromJsonString(jsonString, options);
  }

  static equals(a: CrashStreamResponse_Tx | PlainMessage<CrashStreamResponse_Tx> | undefined, b: CrashStreamResponse_Tx | PlainMessage<CrashStreamResponse_Tx> | undefined): boolean {
    return proto3.util.equals(CrashStreamResponse_Tx, a, b);
  }
}

/**
 * @generated from message BetCrashRequest
 */
export class BetCrashRequest extends Message<BetCrashRequest> {
  /**
   * @generated from field: string session_key = 1;
   */
  sessionKey = "";

  /**
   * @generated from field: int32 bet_amount = 2;
   */
  betAmount = 0;

  /**
   * @generated from field: int32 req_counter = 3;
   */
  reqCounter = 0;

  /**
   * Will contain the cashout value if the player auto cashout
   *
   * @generated from field: optional int32 cashout = 4;
   */
  cashout?: number;

  constructor(data?: PartialMessage<BetCrashRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "BetCrashRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bet_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "req_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "cashout", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BetCrashRequest {
    return new BetCrashRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BetCrashRequest {
    return new BetCrashRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BetCrashRequest {
    return new BetCrashRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BetCrashRequest | PlainMessage<BetCrashRequest> | undefined, b: BetCrashRequest | PlainMessage<BetCrashRequest> | undefined): boolean {
    return proto3.util.equals(BetCrashRequest, a, b);
  }
}

/**
 * @generated from message BetCrashResponse
 */
export class BetCrashResponse extends Message<BetCrashResponse> {
  /**
   * @generated from field: int32 bet_amount = 1;
   */
  betAmount = 0;

  /**
   * @generated from field: int32 req_counter = 2;
   */
  reqCounter = 0;

  /**
   * @generated from field: optional int32 cashout = 3;
   */
  cashout?: number;

  /**
   * @generated from field: bool next_round = 4;
   */
  nextRound = false;

  constructor(data?: PartialMessage<BetCrashResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "BetCrashResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bet_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "req_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "cashout", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "next_round", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BetCrashResponse {
    return new BetCrashResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BetCrashResponse {
    return new BetCrashResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BetCrashResponse {
    return new BetCrashResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BetCrashResponse | PlainMessage<BetCrashResponse> | undefined, b: BetCrashResponse | PlainMessage<BetCrashResponse> | undefined): boolean {
    return proto3.util.equals(BetCrashResponse, a, b);
  }
}

/**
 * @generated from message CashoutCrashRequest
 */
export class CashoutCrashRequest extends Message<CashoutCrashRequest> {
  /**
   * @generated from field: string session_key = 1;
   */
  sessionKey = "";

  /**
   * @generated from field: int32 req_counter = 2;
   */
  reqCounter = 0;

  /**
   * @generated from field: int32 crashpoint = 3;
   */
  crashpoint = 0;

  constructor(data?: PartialMessage<CashoutCrashRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "CashoutCrashRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "req_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "crashpoint", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CashoutCrashRequest {
    return new CashoutCrashRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CashoutCrashRequest {
    return new CashoutCrashRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CashoutCrashRequest {
    return new CashoutCrashRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CashoutCrashRequest | PlainMessage<CashoutCrashRequest> | undefined, b: CashoutCrashRequest | PlainMessage<CashoutCrashRequest> | undefined): boolean {
    return proto3.util.equals(CashoutCrashRequest, a, b);
  }
}

/**
 * @generated from message CashoutCrashResponse
 */
export class CashoutCrashResponse extends Message<CashoutCrashResponse> {
  /**
   * @generated from field: string winnings = 1;
   */
  winnings = "";

  /**
   * @generated from field: int32 req_counter = 2;
   */
  reqCounter = 0;

  constructor(data?: PartialMessage<CashoutCrashResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "CashoutCrashResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "winnings", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "req_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CashoutCrashResponse {
    return new CashoutCrashResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CashoutCrashResponse {
    return new CashoutCrashResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CashoutCrashResponse {
    return new CashoutCrashResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CashoutCrashResponse | PlainMessage<CashoutCrashResponse> | undefined, b: CashoutCrashResponse | PlainMessage<CashoutCrashResponse> | undefined): boolean {
    return proto3.util.equals(CashoutCrashResponse, a, b);
  }
}

/**
 * ----------------------- FLIP ------------------------------
 *
 * @generated from message FlipRequest
 */
export class FlipRequest extends Message<FlipRequest> {
  /**
   * @generated from field: string game_symbol = 1;
   */
  gameSymbol = "";

  /**
   * @generated from field: int32 bet_amount = 2;
   */
  betAmount = 0;

  /**
   * @generated from field: string flip_value = 3;
   */
  flipValue = "";

  /**
   * @generated from field: int32 req_counter = 4;
   */
  reqCounter = 0;

  /**
   * @generated from field: string session_key = 5;
   */
  sessionKey = "";

  constructor(data?: PartialMessage<FlipRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "FlipRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "game_symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bet_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "flip_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "req_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "session_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FlipRequest {
    return new FlipRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FlipRequest {
    return new FlipRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FlipRequest {
    return new FlipRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FlipRequest | PlainMessage<FlipRequest> | undefined, b: FlipRequest | PlainMessage<FlipRequest> | undefined): boolean {
    return proto3.util.equals(FlipRequest, a, b);
  }
}

/**
 * @generated from message FlipResponse
 */
export class FlipResponse extends Message<FlipResponse> {
  /**
   * @generated from field: repeated FlipResponse.Win win = 1;
   */
  win: FlipResponse_Win[] = [];

  /**
   * @generated from field: int32 req_counter = 2;
   */
  reqCounter = 0;

  constructor(data?: PartialMessage<FlipResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "FlipResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "win", kind: "message", T: FlipResponse_Win, repeated: true },
    { no: 2, name: "req_counter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FlipResponse {
    return new FlipResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FlipResponse {
    return new FlipResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FlipResponse {
    return new FlipResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FlipResponse | PlainMessage<FlipResponse> | undefined, b: FlipResponse | PlainMessage<FlipResponse> | undefined): boolean {
    return proto3.util.equals(FlipResponse, a, b);
  }
}

/**
 * @generated from message FlipResponse.Win
 */
export class FlipResponse_Win extends Message<FlipResponse_Win> {
  /**
   * @generated from field: int32 line = 1;
   */
  line = 0;

  /**
   * @generated from field: int32 symbol = 2;
   */
  symbol = 0;

  /**
   * @generated from field: int32 amount_win = 3;
   */
  amountWin = 0;

  constructor(data?: PartialMessage<FlipResponse_Win>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "FlipResponse.Win";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "line", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "symbol", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "amount_win", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FlipResponse_Win {
    return new FlipResponse_Win().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FlipResponse_Win {
    return new FlipResponse_Win().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FlipResponse_Win {
    return new FlipResponse_Win().fromJsonString(jsonString, options);
  }

  static equals(a: FlipResponse_Win | PlainMessage<FlipResponse_Win> | undefined, b: FlipResponse_Win | PlainMessage<FlipResponse_Win> | undefined): boolean {
    return proto3.util.equals(FlipResponse_Win, a, b);
  }
}

