import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  TextField,
  Alert,
  Slide,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import { LoadingButton } from "@mui/lab";

import Iconify from "./Iconify";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import useAuth from "../hooks/useAuth";
import toast from "react-hot-toast";
import TauriInitializer from "./TauriInitializer";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LoginForm() {
  const { login, showLoginForm, setShowLoginForm, isTauriApp } = useAuth();

  const isStaging = import.meta.env.VITE_APP_API.includes("staging");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState(isStaging ? "Ludex@Ludex.gg" : "");
  const [password, setPassword] = useState(isStaging ? "password" : "");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickWebLogin = async () => {
    // Removes JWT token from local storage if it exists
    localStorage.removeItem("jwt");
    try {
      setLoading(true);
      const res = await login({
        email,
        password,
      });
      const response = res as unknown as boolean;

      if (response == true) {
        toast.success("Logged in!");
      } else {
        toast.error("Failed to login");
        throw new Error();
      }
      setShowLoginForm(false);
    } catch (error) {
      console.error(error);
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  if (isTauriApp) {
    return <TauriInitializer />;
  }

  return (
    <Dialog
      open={showLoginForm}
      onClose={() => {
        setShowLoginForm(false);
      }}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        Login
        <IconButton
          aria-label="close"
          onClick={() => {
            setShowLoginForm(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="ep:close-bold" />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          maxWidth: "500px",
          minWidth: "500px",
          backgroundColor: "#3d608c",
          mt: 2,
        }}
      >
        {!isTauriApp && (
          <>
            <TextField
              fullWidth
              label="Email"
              name="Email"
              autoComplete="Email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                mt: 2,
                "& .MuiOutlinedInput-root": {
                  // "& fieldset": {
                  //   borderColor: "white", // This will change the border color
                  // },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white", // This will change the label color
                  "&.Mui-focused": {
                    color: "white",
                  },
                },
                "& .MuiFilledInput-input": {
                  backgroundColor: "lightgray", // change this to your desired color
                },
              }}
            />

            <FormControl
              variant="outlined"
              fullWidth
              sx={{
                mt: 2,
                "& .MuiOutlinedInput-root": {
                  // "& fieldset": {
                  //   borderColor: "white", // This will change the border color
                  // },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white", // This will change the label color
                  "&.Mui-focused": {
                    color: "white",
                  },
                },
                "& .MuiFilledInput-input": {
                  backgroundColor: "lightgray", // change this to your desired color
                },
              }}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                label="Password *"
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </>
        )}

        {error !== "" && (
          <Alert sx={{ mt: 4 }} severity="error">
            {error}
          </Alert>
        )}

        <LoadingButton
          id={"login-button"}
          fullWidth
          variant="contained"
          size="large"
          type="submit"
          loading={loading}
          sx={{
            mt: 4,
            textTransform: "initial",
            background: "linear-gradient(90deg, #FFC107 0%, #FF9800 100%)",
            fontWeight: "bold",
          }}
          onClick={() => handleClickWebLogin()}
        >
          SIGN IN
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
}
